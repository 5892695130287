import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { DetailRequest, RegisterRequest } from "@/api/menu-category/request";
import MenuCategoryDetail from "@/store/menu-category/detail";
import MenuCategoryModify from "@/store/menu-category/modify";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import { UlFileRegisiterTabType } from "@/utils/enums";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest, GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UlFileRegister
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;
  @Prop({ type: String, required: false })
  id?: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "メニュー管理";
  headingSub = "Menu management";

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ
  imageSizePc = {
    width: 96,
    height:96
  }

  // アプリ推奨サイズ
  imageSizeApp = {
    width: 48,
    height: 48
  }

  imageFileSizePc = 50;
  imageFileSizeApp = 50;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  inputParams = {
    /** お知らせ・イベントID */
    id: null as string | null,
    /** 店舗ID */
    shopId: "",
    /** タイトル */
    title: "",
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** 画像パス */
    image: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null,
    /** 公開設定 */
    viewFlg: 1
  } as RegisterRequest;

  //ローディングステータス
  get loading() {
    return Loading.isLoading;
  }

  get isEdit() {
    return !!this.id;
  }

  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "メニュー管理", disabled: true },
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "menu-management" }
        },
        {
          text: "メニューカテゴリ一覧",
          disabled: false,
          to: {
            name: "menu-management-category",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "menu-management" }
        },
        {
          text: "メニューカテゴリ一覧",
          disabled: false,
          to: {
            name: "menu-management-category",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  fileDescription() {
    if (this.agreements.web === true) {
      this.pixelSizeDescription = "※推奨サイズ：横幅" + this.imageSizePc.width + "px 縦幅" + this.imageSizePc.height + "px（推奨サイズ以外の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizePc + "KB以下";
    } else {
      this.pixelSizeDescription = "※推奨サイズ：横幅" + this.imageSizeApp.width + "px 縦幅" + this.imageSizeApp.height + "px（推奨サイズ以外の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizeApp + "KB以下";
    }
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.isEdit) {
      await this.detail();
      this.syncInputParams();
    }
    this.inputParams.shopId = this.shopId;
    // 契約情報を取得する
    const requestAgrement = {
      shopId: this.shopId
    } as GetRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreements.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreements.line = true;
        } else if (item.agreementsType == 3) {
          this.agreements.web = true;
        }
      });
    }
    this.fileDescription();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MenuCategoryDetail.clearResponse();
    await MenuCategoryModify.clearResponse();
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = data;
    this.inputParams.imageId = id;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = null;
    this.inputParams.imageId = null;
  }

  /**
   * 詳細情報取得処理
   */
  async detail() {
    this.isLoading = true;
    await MenuCategoryDetail.detail(this.createDetailRequest());
    if (!MenuCategoryDetail.isSuccess) {
      await Flash.setErrorNow({
        message: MenuCategoryDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 登録処理
   */
  async register() {
    await Flash.clear();
    this.isLoading = true;
    await MenuCategoryModify.register(this.createRegisterRequest());
    if (MenuCategoryModify.isSuccess) {
      this.redirectWithSuccessAlert(
        "メニューカテゴリを保存しました。",
        `/menu-management/${this.shopId}/category`,
        { shopName: this.shopName }
      );
    } else {
      await Flash.setErrorNow({
        message: MenuCategoryModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * APIのレスポンスパラメータをdataにsyncする
   */
  private syncInputParams() {
    this.inputParams = { ...this.inputParams, ...MenuCategoryDetail.getDetail };
    this.initImageUrl = this.inputParams.image;
  }

  /**
   * dataから詳細取得用のリクエストを作成する
   */
  private createDetailRequest() {
    return { id: Number(this.id!) } as DetailRequest;
  }

  /**
   * dataから登録・編集用のリクエストを作成する
   */
  private createRegisterRequest() {
    const request = { ...this.inputParams };
    if (!this.inputParams.id) {
      delete request.id;
    }
    if (
      this.inputParams.imageEditFlg == 0 ||
      this.inputParams.imageId != null
    ) {
      delete request.image;
    }
    return request;
  }
}
