import service from "@/api/service";
import {
  SearchRequest,
  SortRequest,
  DeleteRequest,
  DetailRequest,
  RegisterRequest
} from "@/api/menu-category/request";
import { DetailResponse, SearchResponse } from "@/api/menu-category/response";
import { CommonResponse } from "@/api/response";

/**
 * メニューカテゴリ一覧・検索APIをコールします。
 *
 * @param searchRequest メニューカテゴリ検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-menu-category", searchRequest);
  return response.data as SearchResponse;
}

/**
 * メニューカテゴリ詳細情報取得APIをコールします。
 *
 * @param detailRequest メニューカテゴリ詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-menu-category", detailRequest);
  return response.data as DetailResponse;
}

/**
 * メニューカテゴリ情報登録更新APIをコールします。
 *
 * @param registerRequest メニューカテゴリ情報登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-menu-category", registerRequest);
  return response.data as CommonResponse;
}

/**
 * メニューカテゴリ並び替え登録更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-menu-category", sortRequest);
  return response.data as CommonResponse;
}

/**
 * メニューカテゴリ情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-menu-category", deleteRequest);
  return response.data as CommonResponse;
}
